@import '~@fortawesome/fontawesome-free/css/all.css';

@font-face {
    font-family: "Teko-Bold";
    src: url("../fonts/Teko-Bold.ttf");
}

@font-face {
    font-family: "Teko-Light";
    src: url("../fonts/Teko-Light.ttf");
}

@font-face {
    font-family: "Teko-Medium";
    src: url("../fonts/Teko-Medium.ttf");
}
@font-face {
    font-family: "Teko-Regular";
    src: url("../fonts/Teko-Regular.ttf");
}
@font-face {
    font-family: "Teko-SemiBold";
    src: url("../fonts/Teko-SemiBold.ttf");
}

body {
    background-color: white;
}
#page-container {
    position: relative;
}

.jumbotron h1{
    text-transform: uppercase;
}

.details{
    font-family: Teko-Regular;
    text-transform: uppercase;
    font-size: 1.5em;
}

.title-details {
    font-size: 3em !important;
}

.categories-details{
    color: red;
}

.p-details{
    font-size: 0.75em !important;
    text-transform: none;
}

.categorie-details{
    color: black;
}

.footer{
    bottom: 0;
    width: 100%;
    padding: 2em;
    height: auto;
    font-family: Teko-Light;
    font-size: 1.5em;
    text-transform: uppercase;
    color: white;
}
.text-grey {
    color: grey !important;
}

.bg-black{
    background-color: #111111;
}

.logo-laurier{
    width: 100%;
    height: auto;
}

.navbar{
    height: 5.5em !important;
    font-family: Teko-Light;
    font-size: 1.5em;

    line-height: 1.7em;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 3000;
}


.nav-link{
    font-weight: bold;
}

.nav-item:hover{
    color: white !important;
    opacity: 1 !important;
}
